import React from 'react';
import {Divider, Dropdown, Responsive } from 'semantic-ui-react';
import IELTSLogo from "../../images/go-thru-logo.png";
import IELTSLogo_M from "../../images/go-thru-logo_m.png";
import Link from '@material-ui/core/Link';
import './TopMenu.css';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class TopMenu extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false,
            isAlertOpen: true
        }

        //const handleSelect = (eventKey) => alert(`selected ${eventKey}`);

        this.handleClose = this.handleClose.bind(this);
       
    }

    

    componentDidMount()
    {
        this.setState({isLoggedIn:this.props.isLoggedIn});

        //server maintanence alert 10/04/2021
        // -----------------------------------------------
        // 공지 팝업 설정 
        // on: ''
        // off: false
        localStorage.setItem('alert', 'false');
        // ----------------------------------------------

        const alert = localStorage.getItem("alert");

        //console.log('alert:'+alert);
        
        if(alert === "false")
        {
            this.setState({isAlertOpen:false});
        }
            
    }

    componentWillReceiveProps(nextProps)
    {
        this.setState({isLoggedIn:nextProps.isLoggedIn});
    } 


    handleClose()
    {
        this.setState({isAlertOpen:false});
        localStorage.setItem('alert', false);
    }


    render(){
        const {isLoggedIn} = this.state;
        return (   
            <div className="main_container">
                <Responsive {...Responsive.onlyComputer}>
                    <div className="header">
                    {isLoggedIn && ( 
                        <ul id="horizontal-list">
                            <li><img className="main_logo" src={IELTSLogo} alt="logo"/></li>
                            <li><div className="main_top_empty_bar"></div></li>                        
                            <li>{localStorage.getItem("email")}&nbsp;&nbsp;</li>
                            <li><Link href="/MyPage">My Page</Link>&nbsp;</li>
                            <li><Link href="/Logout">Log out</Link>&nbsp;</li>
                        </ul>
                    )} 
                    {!isLoggedIn && ( 
                        <ul id="horizontal-list">
                            <li><img className="main_logo" src={IELTSLogo} alt="logo"/></li>
                            <li><div className="main_top_empty_bar"></div></li>   
                            <li><Link href="/Authentication">Login</Link>&nbsp;</li>
                            <li><Link href="/SignUp">Sign Up</Link></li>
                        </ul>
                    )}                    
                    </div> 
                    <Divider />
                    
                    <nav className="stroke">
                        <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/Document">Cambridge IELTS</a></li>
                        <li><a href="/Question">IELTS Q & A</a></li>
                        {/*<li><a href="/Blog">Study</a></li>*/}
                        <li><a href="/BuyNow">Pricing</a></li>
                        <li><a href="/Support">Support</a></li>
                        </ul>
                    </nav>  

                    <Dialog
                        open={this.state.isAlertOpen}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"서비스 중지 안내 - Service Update Notice"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            웹 서버 업체의 긴급 보안 업데이트로 인하여 저희 서비스가 다음 시간 동안 원할하지 않을 수 있습니다. 이용에 불편을 드려 죄송합니다.<br></br><br></br>
                            한국 서울 2021년 8월 28일 18:00 - 21:00<br></br>
                            미국 로스앤젤리스 2021년 8월 28일 02:00 - 05:00<br></br>
                            미국 뉴욕 2021년 8월 28일 05:00 - 08:00<br></br>
                            호주 시드니 2021년 8월 28일 19:00 - 22:00<br></br>
                            영국 런던 2021년 8월 28일 10:00 - 13:00<br></br>
                            <br></br>
                            <br></br>
                            Due to our service provider's urgent security update, our web site will get unstable during time below. We appricate your patience.<br></br><br></br>
                            Seoul Korea 28 Aug 2021 18:00 - 21:00<br></br>
                            Los Angeles USA 28 Aug 2021 02:00 - 05:00<br></br>
                            New York USA 28 Aug 2021 05:00 - 08:00<br></br>
                            Sydney Australia 28 Aug 2021 19:00 - 22:00<br></br>
                            London UK 28 Aug 2021 10:00 - 13:00<br></br>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            닫기
                        </Button>
                        </DialogActions>
                    </Dialog>                   
                </Responsive>
                <Responsive {...Responsive.onlyTablet}>
                <div className="header">
                    {isLoggedIn && ( 
                        <ul id="horizontal-list">
                            <li><img className="main_logo" src={IELTSLogo} alt="logo"/></li>
                            <li><div className="main_top_empty_bar"></div></li>                        
                            <li>{localStorage.getItem("email")}&nbsp;&nbsp;</li>
                            <li><Link href="/MyPage">My Page</Link>&nbsp;</li>
                            <li><Link href="/Logout">Log out</Link>&nbsp;</li>
                        </ul>
                    )} 
                    {!isLoggedIn && ( 
                        <ul id="horizontal-list">
                            <li><img className="main_logo" src={IELTSLogo} alt="logo"/></li>
                            <li><div className="main_top_empty_bar"></div></li>   
                            <li><Link href="/Authentication">Login</Link>&nbsp;</li>
                            <li><Link href="/SignUp">Sign Up</Link></li>
                        </ul>
                    )}                    
                    </div> 
                    <Divider />
                    
                    <nav className="stroke">
                        <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/Document">Cambridge IELTS</a></li>
                        <li><a href="/Question">IELTS Q & A</a></li>
                        {/*<li><a href="/Blog">Study</a></li>*/}
                        <li><a href="/BuyNow">Pricing</a></li>
                        <li><a href="/Support">Support</a></li>
                        </ul>
                    </nav>

                    <Dialog
                        open={this.state.isAlertOpen}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"서비스 중지 안내 - Service Update Notice"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            웹 서버 업체의 긴급 보안 업데이트로 인하여 저희 서비스가 다음 시간 동안 원할하지 않을 수 있습니다. 이용에 불편을 드려 죄송합니다.<br></br><br></br>
                            한국 서울 2021년 4월 11일 18:00 - 21:00<br></br>
                            미국 로스앤젤리스 2021년 4월 11일 02:00 - 05:00<br></br>
                            미국 뉴욕 2021년 4월 11일 05:00 - 08:00<br></br>
                            호주 시드니 2021년 4월 11일 19:00 - 22:00<br></br>
                            영국 런던 2021년 4월 11일 10:00 - 13:00<br></br>
                            <br></br>
                            <br></br>
                            Due to our service provider's urgent security update, our web site will get unstable during time below. We appricate your patience.<br></br><br></br>
                            Seoul Korea 11 Apr 2021 18:00 - 21:00<br></br>
                            Los Angeles USA 11 Apr 2021 02:00 - 05:00<br></br>
                            New York USA 11 Apr 2021 05:00 - 08:00<br></br>
                            Sydney Australia 11 Apr 2021 19:00 - 22:00<br></br>
                            London UK 11 Apr 2021 10:00 - 13:00<br></br>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            닫기
                        </Button>
                        </DialogActions>
                    </Dialog>     
                </Responsive>
                <Responsive {...Responsive.onlyMobile}>
                <table>
                    <tbody>
                        <tr>
                            <td><img className="main_logo_m" src={IELTSLogo_M} alt="logo"/></td>
                            <td><div className="main_top_empty_bar_m"></div></td>
                            <td><Dropdown  icon='bars' text='Menu ' direction='left' >                       
                                <Dropdown.Menu>
                                    <Dropdown.Item><Link href="/">HOME</Link></Dropdown.Item>
                                    <Dropdown.Item><Link href="/Document">Cambridge IELTS</Link></Dropdown.Item>
                                    <Dropdown.Item><Link href="/Question">IELTS Q & A</Link></Dropdown.Item>
                                    {/*<Dropdown.Item><Link href="/Blog">Study</Link></Dropdown.Item>*/}
                                    <Dropdown.Item><Link href="/BuyNow">Pricing</Link></Dropdown.Item>
                                    <Dropdown.Item><Link href="/Support">Support</Link></Dropdown.Item>
                                    <Dropdown.Divider />
                                    {isLoggedIn && (                                
                                        <Dropdown.Item><Link href="/MyPage">My Page</Link></Dropdown.Item>
                                    )}
                                    {isLoggedIn && (                                
                                        <Dropdown.Item><Link href="/Logout">Log out</Link></Dropdown.Item>
                                    )}
                                    {!isLoggedIn && (                                
                                        <Dropdown.Item><Link href="/Authentication">Login</Link></Dropdown.Item>
                                    )}                       
                                </Dropdown.Menu>
                            </Dropdown></td>
                        </tr>
                    </tbody>
                </table>    
                <Dialog
                        open={this.state.isAlertOpen}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"서비스 중지 안내 - Service Update Notice"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            웹 서버 업체의 긴급 보안 업데이트로 인하여 저희 서비스가 다음 시간 동안 원할하지 않을 수 있습니다. 이용에 불편을 드려 죄송합니다.<br></br><br></br>
                            한국 서울 2021년 4월 11일 18:00 - 21:00<br></br>
                            미국 로스앤젤리스 2021년 4월 11일 02:00 - 05:00<br></br>
                            미국 뉴욕 2021년 4월 11일 05:00 - 08:00<br></br>
                            호주 시드니 2021년 4월 11일 19:00 - 22:00<br></br>
                            영국 런던 2021년 4월 11일 10:00 - 13:00<br></br>
                            <br></br>
                            <br></br>
                            Due to our service provider's urgent security update, our web site will get unstable during time below. We appricate your patience.<br></br><br></br>
                            Seoul Korea 11 Apr 2021 18:00 - 21:00<br></br>
                            Los Angeles USA 11 Apr 2021 02:00 - 05:00<br></br>
                            New York USA 11 Apr 2021 05:00 - 08:00<br></br>
                            Sydney Australia 11 Apr 2021 19:00 - 22:00<br></br>
                            London UK 11 Apr 2021 10:00 - 13:00<br></br>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            닫기
                        </Button>
                        </DialogActions>
                    </Dialog>                        
                </Responsive>
            </div>
        );
    }
};

export default TopMenu;